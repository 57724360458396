import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import qs from 'query-string';

import { AuthLocalStorageKeys, refreshTokenIfPossible, redirectToLogin } from './AuthContext/AuthAPI';

const { CancelToken } = axios;

class Request {
  cancelToken: CancelTokenSource
  url: string;
  wasCancelled: boolean;

  constructor(url: string) {
    this.url = url;
    this.cancelToken = CancelToken.source();
    this.wasCancelled = false;
  }

  static cancelled = (error: any) => axios.isCancel(error);

  async get<T = any>(options: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.get<T>(this.url, this.updateOptions(options)));
  }

  /**
   * Provides default parameters for retrieving a file
   * @param {Object} options the axios options
   */
   async getFile(options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.timeout = options.timeout ?? 600000;
    options.responseType = options.responseType ?? 'blob';
    options.headers = options.headers ?? {};
    options.headers['Cache-Control'] = options.headers['Cache-Control'] ?? 'no-cache, no-store';
    options.headers.Pragma = options.headers.Pragma ?? 'no-cache';
    options.headers.Expires = options.headers.Expires ?? '0';
    return this.get(options);
  }

  async head(options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(() => axios.head(this.url, this.updateOptions(options)));
  }


  async post(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.post(this.url, data, this.updateOptions(options)));
  }

  async postRequestToGetFile(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.timeout = options.timeout ?? 600000;
    options.responseType = options.responseType ?? 'blob';
    options.headers = options.headers ?? {};
    options.headers['Cache-Control'] = options.headers['Cache-Control'] ?? 'no-cache, no-store';
    options.headers.Pragma = options.headers.Pragma ?? 'no-cache';
    options.headers.Expires = options.headers.Expires ?? '0';
    return this.post(data, options);
  }

  async put(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.put(this.url, data, this.updateOptions(options)));
  }

  async patch(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.patch(this.url, data, this.updateOptions(options)));
  }

  async delete(options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.delete(this.url, this.updateOptions(options)));
  }

  deleteWithData(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    options.data = data;
    return this.catchUnauthorized(() => axios.delete(this.url, this.updateOptions(options)));
  }


  cancel(message?: string) {
    this.cancelToken.cancel(message);
    this.wasCancelled = true;
  }

  updateOptions(options: AxiosRequestConfig): AxiosRequestConfig {
    options = options || {};
    return {
      ...options,
      cancelToken: this.cancelToken.token,
      headers: {
        ...(options.headers || {}),
        Authentication: localStorage.getItem(AuthLocalStorageKeys.TOKEN),
        'X-Proxy-Options': 'raw',
      },
      paramsSerializer: params => qs.stringify(params),
    };
  }

  async catchUnauthorized(makeRequest: () => Promise<AxiosResponse>): Promise<AxiosResponse> {
    await refreshTokenIfPossible();

    try {
      const request = makeRequest();
      return await request;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        redirectToLogin();
      }
      throw error;
    }
  }
}

export default Request;
