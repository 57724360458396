// @ts-ignore
import { init as initApm } from '@elastic/apm-rum';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-empty-function
let captureError = (e: Error): void => {};

const configureApm = (apmURL: string, environment: string, serviceName: string, version: string): void => {
  const apm = initApm({
    serviceName,
    serverUrl: apmURL,
    pageLoadTransactionName: 'reactLoad',
    environment,
    serviceVersion: version
  });
  captureError = apm.captureError.bind(apm);
};

export default {
  captureError,
  configureApm
};
