import React, { ReactChild } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthContext } from './AuthContext';
import { setPostLoginRedirect } from './AuthContext/AuthAPI';

interface ProtectedRouteProps extends RouteProps {
  children: ReactChild;
  requiredPermissions?: string[];
}

const ProtectedRoute = ({
  children,
  requiredPermissions = [],
  ...rest
}: ProtectedRouteProps) => {
  const { isLoading, isAuthEnabled, isAuthenticated, loginPath, hasAllPermissions } = useAuthContext();

  const hasPermission = requiredPermissions ? hasAllPermissions(requiredPermissions) : true;

  if (!isLoading && !hasPermission) {
    return <Redirect to="/" />;
  }

  if (!isAuthenticated) {
    // We're going to redirect the user so we should mark the path such that the
    // user will come back here later
    setPostLoginRedirect(window.location.pathname);
  }

  return (
    !isLoading ? (
      <Route {...rest}>
        {isAuthenticated || !isAuthEnabled ? (
          children
        ) : (
          <Redirect to={loginPath} />
        )}
      </Route>
    ) : null
  );
};

export default ProtectedRoute;