import qs from 'query-string';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import {
  checkAuth,
  getPostLoginRedirect,
  getUser,
  handleAuthCallback,
  loadInitialAppData,
  login,
  logout,
  AuthLocalStorageKeys,
  LoginData,
} from './AuthAPI';

type AuthContextProps = {
  getToken: () => string|null;

  // Does the user have all of these permissions?
  hasAllPermissions: (permissions: string[]) => boolean;

  // Does the user have one or more of these permissions?
  hasSomePermissions: (permissions: string[]) => boolean;

  // Is auth enabled on this system?
  isAuthEnabled: boolean;

  // If isAuthEnabled, is the user authenticated
  isAuthenticated: boolean;

  // If isAuthEnabled, is data still loading
  isLoading: boolean;

  // The app route to the oauth callback
  loginCallbackPath: string;

  // Data about the client we are logging in withj
  loginData: LoginData,

  // The app route to the login page
  loginPath: string;

  // User initiated login process
  login: () => void;

  // Logout from the app + IdP
  logout: () => void;

  // The permissions that the user has
  permissions: Set<string>|{ has: () => boolean};

  productDetails: {
    // Google Analytics ID if set
    GA?: string;

    // GridOS version
    version: string;
  },

  user: {
    email: string;
    userID: string;
  }
};

const initialState = {
  getToken: () => localStorage.getItem(AuthLocalStorageKeys.TOKEN),
  hasAllPermissions: () => false,
  hasSomePermissions: () => false,
  isAuthEnabled: true,
  isAuthenticated: false,
  isLoading: true,
  // We need to provide defaults here, so disable eslint
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  loginPath: '/login',
  loginCallbackPath: '/login/oauth-callback',
  loginData: {
    authorization_endpoint: '',
    audience: '',
    client_id: '',
    scope: '',
    response_type: '',
  },
  permissions: new Set<string>(),
  productDetails: {
    version: '',
  },
  user: {
    email: '',
    userID: '',
  }
};

export const AuthContext = createContext<AuthContextProps>(initialState);
export const useAuthContext = () => useContext(AuthContext);

type AuthContextProviderProps = {
  // If defined, this string is prepended to the server root
  // when loading static data (i.e. not from /api or /auth)
  baseURL?: string;

  children: ReactNode;

  // The relative path in the app to the login page. Defaults to '/login'
  loginPath?: string;

  // The relative path in the app to the oauth callback page.
  // Defaults to '/login/oauth-callback'
  loginCallbackPath?: string;

  // The name of the service for APM logging
  serviceName: string;
};

export const AuthContextProvider = ({
  baseURL = '',
  children,
  loginPath = '/login',
  loginCallbackPath = '/login/oauth-callback',
  serviceName,
}: AuthContextProviderProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onLoginRoute = useRouteMatch(loginPath);
  const onCallbackRoute = useRouteMatch(loginCallbackPath);

  const [authState, setAuthState] = useState<AuthContextProps>({
    ...initialState,
    loginPath,
    loginCallbackPath,
  });
  // This state is kept separately since we load this data first
  const [isLoadingProductData, setIsLoadingProductData] = useState(true);

  const hasAllPermissions = useMemo(
    () => (permissions: string[]) => permissions.every(p => authState.permissions.has(p)),
    [authState],
  );
  const hasSomePermissions = useMemo(
    () => (permissions: string[]) => permissions.some(p => authState.permissions.has(p)),
    [authState],
  )

  const loginHandler = useMemo(() => () => login(loginCallbackPath, authState.loginData), [authState.loginData, loginCallbackPath]);
  const logoutHandler = useMemo(() => () => { logout(loginPath, authState.loginData) }, [authState.loginData, loginPath]);

  useEffect(() => {
    // When the app loads, learn things about our current environment
    async function initEnvironmentData() {
      try {
        const { environment, loginData, services } = await loadInitialAppData(serviceName, baseURL);
        setAuthState(a => ({
          ...a,
          isAuthEnabled: services.auth,
          loginData,
          productDetails: {
            GA: environment.GA,
            version: environment.version,
          },
        }));
      } catch {
        // Something is horribly wrong.
        // Assume auth is enabled and user is not authenticated
        setAuthState(a => ({
          ...a,
          isAuthEnabled: true,
          isAuthenticated: false,
        }));
      } finally {
        setIsLoadingProductData(false);
      }
    }

    initEnvironmentData();
  }, [serviceName, baseURL]);

  useEffect(() => {
    if (isLoadingProductData) {
      // Wait until we know about our service data before continuing
      return;
    }

    if (!authState.isAuthEnabled) {
      // No auth is enabled, so we don't need to do anything
      if (authState.isLoading) {
        setAuthState({
          ...authState,
          isAuthenticated: true,
          isLoading: false,
          permissions: {
            has: () => true,
          },
        });
      }
      return;
    }

    const invalidateAuth = () => {
      setAuthState(a => ({
        ...a,
        isLoading: false,
      }));
      history.push(loginPath);
    };
  
    const validateAuthResponse = async () => {
      const query = qs.parse(search);
      const success = await handleAuthCallback(query);
      const { permissions, user } = await getUser();
      if (success && user) {
        setAuthState({
          ...authState,
          isAuthenticated: true,
          isLoading: false,
          permissions,
          user,
        });
        history.push(getPostLoginRedirect());
      } else {
        invalidateAuth();
      }
    };
  
    const validateAuthState = async () => {
      try {
        const { permissions, user } = await checkAuth();
  
        setAuthState(a => ({
          ...a,
          permissions,
          isAuthenticated: true,
          isLoading: false,
          user: user,
        }));
  
        if (onLoginRoute) {
          history.push(getPostLoginRedirect());
        }
      } catch (error) {
        invalidateAuth();
      }
    };

    if (authState.isLoading && onCallbackRoute) {
      validateAuthResponse();
    } else if (onLoginRoute) {
      if (authState.isLoading && !authState.isAuthenticated) {
        setAuthState({
          ...authState,
          isLoading: false,
        });
      }
    } else if (authState.isLoading || (authState.isAuthEnabled && !authState.isAuthenticated)) {
      // On a normal route
      validateAuthState();
    }
  }, [isLoadingProductData, authState, onCallbackRoute, onLoginRoute, history, loginPath, search])

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        hasAllPermissions,
        hasSomePermissions,
        login: loginHandler,
        logout: logoutHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
};